h1 {
  margin: 0;
  padding: 0;
}

.student-life-container {
  width: 900px;
  /* height: 600px; */
  /* border: 2px solid black; */
  margin: 10px;
  
  @media (max-width: 600px) {
    width: 100%;
  }
}

.student-life-container p {
  font-size: 1.1rem;
}

.control[data-inactive] {
  opacity: 0;
  cursor: default;
}
