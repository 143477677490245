.mantine-Card-section {
    width: '300px';
    height: '300px';
    margin: '10px';
}

.videography-container {
    background-color: ivory;
    display: flex;
    flex: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
}

.mantine-Card-root {
    background-color: #d4af37; 
    /* border: 1px solid #6f6f78; */
    border: none;
}

.mantine-Card-root:hover {
    background-color: #d4af37; 
    box-shadow: 0 0 20px #d4af37;
    cursor: pointer;
}

.videography-title {
    font-family: Verdana;
    font-weight: 700;
    text-align: center;
    margin: 0;
    margin-top: 10px;
    font-size: 1.1rem;
    color: #232323;
}

.card-style {
    margin: 10;
    width: 282;
    height: 310; 
    margin-bottom: 30px;
    /* outline: 10px solid #003366; */
}

a {
    text-decoration-line: none !important; 
}
