.home {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(170, 104, 184);
  position: relative;
}

.recap-button {
  cursor: pointer;
  color: ivory;
  font-size: 4rem;
}

.recap-button:hover {
  text-shadow: 5px 5px red;
}

.date-button {
  cursor: pointer;
  color: rgb(232, 232, 135);
  font-size: 4rem;
}

.date-button:hover {
  text-shadow: 5px 5px rgb(255, 77, 0);
}

.month-button {
  font-size: 1rem;
  color: ivory;
  text-shadow: 2px 2px red;
  margin: 5px;
  cursor: pointer;
}

.month-button-special {
  font-size: 1.4rem;
  color: ivory;
  text-shadow: 2px 2px red;
  margin: 5px;
  cursor: pointer;
}

.month-button:hover {
  font-size: 1.4rem;
}

.months-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}