.mantine-Grid-inner {
    display: flex;
    justify-content: center;
}

.about-me-section {
    padding: 50px 0;
}
  
.about-me-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.profile-image {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin: 20px auto;
}

.student-life-titles {
    color: 	#003366;
    text-align: center;
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.professional-title {
    color: 	#330066;
    margin: 2px;
    font-size: 1.2rem;
    text-align: center;
}

p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #444;
}

.professional-images {
    width: 340px;
    height: 200px;
    margin: 10px;
    margin-bottom: 0;
    border-radius: 5px;
    overflow: hidden;
    object-fit: cover; 
}

.professional-container {
    padding: 5px;
    border-radius: 15px;
    background-color: 	#aea397;
    margin: 7px;
    transition: box-shadow 0.3s ease;
    border: 2px solid #958778;
    height: fit-content;
}

.professional-container:hover {
    padding: 5px;
    border-radius: 15px;
    background-color: 	#aea397;
    margin: 7px;
    box-shadow: 0 0 13px #330066;
    cursor: pointer;
    height: fit-content;
}

.professional-container-selected {
    border: 2px solid #958778;
    padding: 5px;
    border-radius: 15px;
    background-color: 	#aea397;
    margin: 7px;
    box-shadow: 0 0 13px #330066;
    cursor: pointer;
    height: fit-content;
}

.professional-image-text p {
    max-width: 340px;
    font-size: .8rem;
    margin: 0;
    text-align: center;
}

.student-life-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mantine-Carousel-controls {
    transition: opacity 150ms ease;
    opacity: 0;
  }
  
.mantine-Carousel-root:hover .mantine-Carousel-controls {
    opacity: 1;
}

body {
    background-color: ivory;
    margin: 0;
    padding: 0; 
}

.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade-in.visible {
    opacity: 1;
  }